<template>
    <div class="exams content-padding">
        <SvgLoader v-if="loading" :loading="true" bg-color="transparent" />
        <div v-else>
            <div v-if="!showExamEditor" class="general-settings">
                <BaseCard>
                    <div class="option">
                        <div class="info">
                            <h6>
                                {{ labels.read_content }}
                            </h6>
                            <p>
                                {{ labels.count_exam_as_passed_when_user_finished_reading_the_content }}
                            </p>
                        </div>
                        <div @click="toggleExamTypeFn('readContentToPass', !readContentToPass)" class="selection">
                            <div v-if="readContentToPass" class="selected"></div>
                        </div>
                    </div>
                </BaseCard>
                <BaseCard>
                    <div class="option">
                        <div class="info">
                            <h6>
                                {{ labels.multiple_choice_test }}
                            </h6>
                            <p>
                                {{ labels.create_exam_description_text }}
                            </p>
                        </div>
                        <div @click="toggleExamTypeFn('multipleChoiceTest', !multipleChoiceTest)" class="selection">
                            <div v-if="multipleChoiceTest" class="selected"></div>
                        </div>
                    </div>
                    <button v-if="examEditorEnabled" @click="showExamEditor = true" class="btn btn-primary">
                        {{ labels.open_test }}
                    </button>
                </BaseCard>
                <BaseCard v-if="singleData.exam && singleData.exam.participants && singleData.exam.participants.length">
                    <div class="option">
                        <div class="info">
                            <h6 class="participant">
                                {{ labels.exam_participants }}
                            </h6>
                            <p v-for="(participant, index) in singleData.exam.participants" :key="`participant-${index}`" class="participant">
                                {{ participant.niceName }}
                                <span>
                                    {{ labels.score }}: {{ participant.totalCorrect }}/{{ participant.totalQuestions }}
                                </span>
                            </p>
                        </div>
                    </div>
                </BaseCard>
            </div>
            <div v-else class="editor">
                <BaseCard>
                    <div class="header-controls">
                        <div class="exam-info">
                            <h6>
                                {{ labels.multiple_choice_test }}: {{ singleData.title }}
                            </h6>
                            <h6>
                                {{ labels.exam_of_module }}: <a href="#" _target="blank">{{ labels.visit }}</a>
                            </h6>
                        </div>
                        <button @click="showExamEditor = false" class="btn btn-primary">
                            {{ labels.back_to_exam_settings }}
                        </button>
                        <button @click="saveExamQuestionsFn" class="btn btn-success">
                            {{ labels.save }}
                        </button>
                    </div>
                </BaseCard>
                <BaseCard>
                    <FormulateInput
                        type="number"
                        :label="labels.required_amount_of_correct_answers_to_pass"
                        :value="correctAnswersToPass"
                        v-model="correctAnswersToPass"
                        min="0"
                        :max="questions.length"
                    />
                </BaseCard>
                <BaseCard>
                    <SimpleList
                        :label="labels.questions"
                        :current-value="questions"
                        :structure="questionsStructure"
                        :add-new-item-label="labels.add_new_question"
                        @list-updated="questionsUpdated"
                    />
                </BaseCard>
                <TextEditorCard
                    :extra-configs="{
                        hideBgColorPicker: true,
                        hideColorPicker: true,
                    }"
                    :title="labels.text_after_exam_fail"
                    :value="afterExamText"
                    @changed="localDataUpdated('afterExamText', $event.value)"
                />
                <TextEditorCard
                    :extra-configs="{
                        hideBgColorPicker: true,
                        hideColorPicker: true,
                    }"
                    :title="labels.text_after_exam_succeeded"
                    :value="afterSuccessExamText"
                    @changed="localDataUpdated('afterSuccessExamText', $event.value)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from "vuex";

    export default {
        name: 'ExamEditor',
        components: {
            BaseCard: () => import('@/components/Cards/BaseCard.vue'),
            SimpleList: () => import('@/components/List/SimpleList'),
            SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
            TextEditorCard: () => import('@/components/Input/TextEditorCard')
        },
        mounted() {
            if (Object.keys(this.singleData).length) {
                this.assignStateToLocal();
                this.loading = false;
            }
        },
        computed: {
            ...mapGetters("helper", ["labels"]),
            ...mapState("_module", ["singleData"]),
            examEditorEnabled() {
                if (this.multipleChoiceTest) return true;

                return false;
            },
            questionsStructure() {
                return {
                    id: {
                        kind: 'id'
                    },
                    question: {
                        label: this.labels.question,
                        kind: 'richtext'
                    },
                    answers: {
                        label: this.labels.answers,
                        kind: 'list',
                        addNewItemLabel: this.labels.add_new_answer,
                        structure: {
                            id: {
                                kind: 'id'
                            },
                            answer: {
                                label: this.labels.answer,
                                kind: 'richtext'
                            },
                            isCorrect: {
                                label: this.labels.is_correct,
                                kind: 'checkbox'
                            }
                        }
                    }
                }
            }
        },
        methods: {
            ...mapActions('_module', ['toggleExamType', 'saveExamQuestions']),
            ...mapActions('notification', ['addNotification']),
            localDataUpdated(key, value) {
                this[key] = value;
            },
            questionsUpdated(newQuestions) {
                this.questions = newQuestions;
            },
            toggleExamTypeFn(kind, val) {
                this.loading = true;

                this.toggleExamType({
                    id: this.$route.params.id,
                    examType: kind,
                    value: val
                }).then((res) => {
                    if (res.success) {
                        this[kind] = val;

                        this.addNotification({
                            variant: "success",
                            msg: ["saved"],
                            labels: this.labels,
                            dismissSecs: 5
                        });
                    } else if (res.error) {
                        this.addNotification({
                            variant: "danger",
                            msg: res.error,
                            labels: this.labels
                        });
                    }
                }).catch((err) => console.log(err));

                this.loading = false;
            },
            saveExamQuestionsFn() {
                if ((!this.correctAnswersToPass && this.correctAnswersToPass != 0) || this.correctAnswersToPass < 0) {
                    this.addNotification({
                        variant: "danger",
                        msg: 'required_amount_for_questions_is_zero',
                        labels: this.labels
                    });

                    return;
                }

                if (this.correctAnswersToPass && parseInt(this.correctAnswersToPass) > this.questions.length) {
                    this.addNotification({
                        variant: "danger",
                        msg: 'required_amount_of_correct_questions_exceeds_questions_amount',
                        labels: this.labels
                    });

                    return;
                }

                this.loading = true;

                this.saveExamQuestions({
                    id: this.$route.params.id,
                    questions: this.questions,
                    correctAnswersToPass: this.correctAnswersToPass,
                    afterExamText: this.afterExamText,
                    afterSuccessExamText: this.afterSuccessExamText
                }).then((res) => {
                    if (res.success) {
                        this.addNotification({
                            variant: "success",
                            msg: ["saved"],
                            labels: this.labels,
                            dismissSecs: 5
                        });
                    } else if (res.error) {
                        this.addNotification({
                            variant: "danger",
                            msg: res.error,
                            labels: this.labels
                        });
                    }
                }).catch((err) => console.log(err));

                this.loading = false;
            },
            assignStateToLocal() {
                if (this.singleData?.exam?.readContentToPass) this.readContentToPass = true;
                if (this.singleData?.exam?.multipleChoiceTest) this.multipleChoiceTest = true;
                if (this.singleData?.exam?.correctAnswersToPass) this.correctAnswersToPass = this.singleData.exam.correctAnswersToPass;
                if (this.singleData?.exam?.questions) this.questions = this.singleData.exam.questions;
                if (this.singleData?.exam?.afterExamText) this.afterExamText = this.singleData.exam.afterExamText;
                if (this.singleData?.exam?.afterSuccessExamText) this.afterSuccessExamText = this.singleData.exam.afterSuccessExamText;
            }
        },
        data() {
            return {
                showExamEditor: false,
                readContentToPass: false,
                multipleChoiceTest: false,
                correctAnswersToPass: 0,
                questions: [],
                afterExamText: '',
                afterSuccessExamText: '',
                loading: true
            }
        },
        watch: {
            singleData(newVal, oldVal) {
                if (!Object.keys(oldVal).length && Object.keys(newVal).length) {
                    this.assignStateToLocal();
                    this.loading = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .exams {
        .base-card {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        .option {
            display: flex;
            user-select: none;

            .info {
                margin-right: 30px;
                margin-bottom: 1rem;
                width: 100%;

                > p:not(.participant) {
                    margin-bottom: 0;
                }

                h6.participant {
                    margin-bottom: 1rem;
                }

                p.participant {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        margin-left: auto;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:not(:last-child) {
                        padding-bottom: 1rem;
                        margin-bottom: 1rem;
                        border-bottom: 1px solid rgba(0,0,0,0.3);
                    }
                }
            }

            .selection {
                width: 25px;
                height: 25px;
                border: 2px solid $purple;
                margin-left: auto;
                border-radius: 50%;
                cursor: pointer;
                padding: 5px;

                .selected {
                    width: 100%;
                    height: 100%;
                    background-color: $purple;
                    border-radius: 50%;
                }
            }
        }

        .editor {
            .header-controls {
                display: flex;

                .exam-info {
                    margin-right: 30px;

                    h6:last-child {
                        margin-bottom: 0;
                    }
                }

                button.btn-primary {
                    margin-left: auto;
                    margin-right: 1rem;
                }
            }
        }
    }
</style>
